<template>
  <div class="sideClient">
    <nav>
      <div class="sidebar" v-if="getTabs">
        <div class="title">
          <h1>
            {{ $t("GENERAL") }}
            <svg
              class="bars"
              @click="changeTabs"
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="18"
              viewBox="0 0 27 18"
            >
              <path
                id="Icon_material-menu"
                data-name="Icon material-menu"
                d="M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z"
                transform="translate(-4.5 -9)"
                fill="#fff"
              />
            </svg>
          </h1>

          <ul class="subtitle">
            <li
              v-if="
                isentrepriseAdmin ||
                isSuperAdmin ||
                isAssistant ||
                isTechnicien ||
                isCommercial
              "
            >
              <router-link to="/client">
                <font-awesome-icon icon="user" class="svg" />
                {{ $t("CUSTOMER") }}
              </router-link>
            </li>
            <li
              v-if="
                isentrepriseAdmin ||
                isSuperAdmin ||
                isAssistant ||
                isCommercial ||
                isTechnicien ||
                isUserCLient
              "
            >
              <router-link to="/devis/list">
                <svg
                  class="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="15"
                  viewBox="0 0 27 15"
                >
                  <path
                    id="Icon_material-list"
                    data-name="Icon material-list"
                    d="M4.5,19.5h3v-3h-3Zm0,6h3v-3h-3Zm0-12h3v-3h-3Zm6,6h21v-3h-21Zm0,6h21v-3h-21Zm0-15v3h21v-3Z"
                    transform="translate(-4.5 -10.5)"
                    fill="#fff"
                  />
                </svg>
                {{ $t("ESTIMATE") }}
              </router-link>
            </li>
            <!-- <li
              v-if="
                isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial
              "
            >
              <router-link to="/devis/manuel">
                <svg
                  class="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.191"
                  height="28.279"
                  viewBox="0 0 24.191 28.279"
                >
                  <path
                    id="Icon_payment-invoice-sign-o"
                    data-name="Icon payment-invoice-sign-o"
                    d="M34.539,22.37a3.99,3.99,0,0,1,.773,1.16,3.308,3.308,0,0,1,.276,1.381V43.137a1.323,1.323,0,0,1-.442,1.049,1.431,1.431,0,0,1-1.049.442H12.889a1.429,1.429,0,0,1-1.049-.442,1.32,1.32,0,0,1-.442-1.049v-25.3a1.321,1.321,0,0,1,.442-1.049,1.432,1.432,0,0,1,1.049-.442H27.028a3.287,3.287,0,0,1,1.381.331,4.157,4.157,0,0,1,1.215.718ZM33.6,42.585V26.457H27.028a1.429,1.429,0,0,1-1.049-.442,1.386,1.386,0,0,1-.442-1.1V18.393H13.386V42.584H33.6ZM27.525,18.5v5.91H33.49a1.013,1.013,0,0,0-.387-.607l-4.915-4.971a1.792,1.792,0,0,0-.663-.331Z"
                    transform="translate(-11.398 -16.35)"
                    fill="#fff"
                  />
                </svg>

                {{ $t("NEW") }} {{ $t("ESTIMATE") }}
              </router-link>
            </li> -->
            <li
              v-if="
                isentrepriseAdmin ||
                isSuperAdmin ||
                isComptable ||
                isAssistant ||
                isCommercial ||
                isUserCLient
              "
            >
              <router-link to="/facture/list">
                <font-awesome-icon icon="receipt" class="svg" />
                {{ $t("INVOICE") }}
              </router-link>
            </li>
            <li
              v-if="
                isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial
              "
            >
              <router-link to="/facture">
                <font-awesome-icon icon="file-invoice-dollar" class="svg" />
                {{ $t("CREATE") }} {{ $t("INVOICE") }}
              </router-link>
            </li>
            <li v-if="!isComptable">
              <router-link to="/agenda">
                <font-awesome-icon icon="fa-solid fa-calendar" class="svg" />
                {{ $t("AGENDA") }}
              </router-link>
            </li>
            <!--<li>
              <router-link
                to="/statistique"
              >
                <font-awesome-icon icon="signal" class="svg" />
                {{ $t('STATISTICAL') }}
              </router-link>
            </li>-->
          </ul>
        </div>

        <div class="title" v-if="isUserCLient">
          <h1>{{ $t("Contact artisant") }}</h1>
          <div class="block">
            <p>Nom: {{ getOnlineUser.entreprise.lib }}</p>
            <p>Adresse:</p>
            <p>
              {{ getOnlineUser.entreprise.rue }},
              {{ getOnlineUser.entreprise.cp }},
              {{ getOnlineUser.entreprise.ville }}
            </p>
            <p>Numéro: {{ getOnlineUser.entreprise.tel }}</p>
            <p>Email: {{ getOnlineUser.entreprise.email }}</p>
          </div>
        </div>
      </div>

      <div class="sidebar minim" v-if="!getTabs">
        <div class="title">
          <h1>
            <svg
              class="bars"
              @click="changeTabs"
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="18"
              viewBox="0 0 27 18"
            >
              <path
                id="Icon_material-menu"
                data-name="Icon material-menu"
                d="M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z"
                transform="translate(-4.5 -9)"
                fill="#fff"
              />
            </svg>
          </h1>

          <ul class="subtitle">
            <li
              v-if="
                isentrepriseAdmin ||
                isSuperAdmin ||
                isAssistant ||
                isTechnicien ||
                isCommercial
              "
              :title="$t('CUSTOMER')"
            >
              <router-link to="/client">
                <font-awesome-icon icon="user" class="svg" />
              </router-link>
            </li>
            <li
              :title="$t('ESTIMATE')"
              v-if="
                isentrepriseAdmin ||
                isSuperAdmin ||
                isAssistant ||
                isCommercial ||
                isTechnicien ||
                isUserCLient
              "
            >
              <router-link to="/devis/list">
                <svg
                  class="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="15"
                  viewBox="0 0 27 15"
                >
                  <path
                    id="Icon_material-list"
                    data-name="Icon material-list"
                    d="M4.5,19.5h3v-3h-3Zm0,6h3v-3h-3Zm0-12h3v-3h-3Zm6,6h21v-3h-21Zm0,6h21v-3h-21Zm0-15v3h21v-3Z"
                    transform="translate(-4.5 -10.5)"
                    fill="#fff"
                  />
                </svg>
              </router-link>
            </li>
            <!-- <li
              :title="$t('ESTIMATE')"
              v-if="
                isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial
              "
            >
              <router-link to="/devis/manuel">
                <svg
                  class="svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24.191"
                  height="28.279"
                  viewBox="0 0 24.191 28.279"
                >
                  <path
                    id="Icon_payment-invoice-sign-o"
                    data-name="Icon payment-invoice-sign-o"
                    d="M34.539,22.37a3.99,3.99,0,0,1,.773,1.16,3.308,3.308,0,0,1,.276,1.381V43.137a1.323,1.323,0,0,1-.442,1.049,1.431,1.431,0,0,1-1.049.442H12.889a1.429,1.429,0,0,1-1.049-.442,1.32,1.32,0,0,1-.442-1.049v-25.3a1.321,1.321,0,0,1,.442-1.049,1.432,1.432,0,0,1,1.049-.442H27.028a3.287,3.287,0,0,1,1.381.331,4.157,4.157,0,0,1,1.215.718ZM33.6,42.585V26.457H27.028a1.429,1.429,0,0,1-1.049-.442,1.386,1.386,0,0,1-.442-1.1V18.393H13.386V42.584H33.6ZM27.525,18.5v5.91H33.49a1.013,1.013,0,0,0-.387-.607l-4.915-4.971a1.792,1.792,0,0,0-.663-.331Z"
                    transform="translate(-11.398 -16.35)"
                    fill="#fff"
                  />
                </svg>
              </router-link>
            </li> -->

            <li
              :title="$t('INVOICE')"
              v-if="
                isentrepriseAdmin ||
                isSuperAdmin ||
                isComptable ||
                isAssistant ||
                isCommercial ||
                isUserCLient
              "
            >
              <router-link to="/facture/list">
                <font-awesome-icon icon="receipt" class="svg" />
              </router-link>
            </li>
            <li
              :title="$t('INVOICE')"
              v-if="
                isentrepriseAdmin || isSuperAdmin || isAssistant || isCommercial
              "
            >
              <router-link to="/facture">
                <font-awesome-icon icon="file-invoice-dollar" class="svg" />
              </router-link>
            </li>
            <li :title="$t('AGENDA')" v-if="!isComptable">
              <router-link to="/agenda">
                <font-awesome-icon icon="fa-solid fa-calendar" class="svg" />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  methods: {
    ...mapActions(["changeTabs", "saveLang", "pays"]),
  },
  computed: {
    ...mapGetters([
      "getTabs",
      "getAllLangue",
      "getLoadingdictionnaire",
      "getSelectedLang",
      "getOnlineUser",
    ]),
    utilsRouterLinkActive: function () {
      return this.$route.name;
    },
    newDevis() {
      return (
        "Nouveau Devis" == this.utilsRouterLinkActive ||
        "Devis Etape" == this.utilsRouterLinkActive ||
        "Devis Manuel" == this.utilsRouterLinkActive
      );
    },
    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isFirstAdmin() {
      return this.getOnlineUser.first_admin;
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isTechnicien() {
      return this.getOnlineUser.role == "user.technicien";
    },
    isComptable() {
      return this.getOnlineUser.role == "user.comptable";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },
    isUserCLient() {
      return this.getOnlineUser.role == "user.client";
    },
  },
};
</script>

<style lang="scss" scoped>
.sideClient {
  @media only screen and (max-width: 900px) {
    display: none;
  }
}
.minim {
  padding: 42px 0 !important;
  width: 64px !important;

  .bars {
    top: -32px !important;
    left: 18px !important;
  }

  h1::after {
    margin: 4px 0 0 !important;
  }
  a {
    justify-content: center !important;

    .svg {
      margin: 0 !important;
    }
  }
}

.sidebar {
  background-color: #28367a;
  width: 220px;
  height: 100vh;
  margin-top: 57px;
  padding: 24px 0;
  position: fixed;

  ul {
    margin: 0;
  }

  .subtitle {
    list-style: none;
  }

  h1 {
    padding: 0 10px;
    margin: 0;
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: #616a92;
  }

  h1::after {
    content: "";
    border-top: 2px solid #fff;
    display: flex;
    margin: 0.5rem 0 0;
    width: 100%;
  }

  a {
    padding-left: 32px;
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    color: #fff;
    transition: all 0.2s ease;
    text-decoration: none;
    padding: 12px 16px;

    &:hover {
      background-color: #3a4891;
    }

    .svg {
      width: 20px;
      height: 20px;
      margin-right: 14px;
    }

    &.router-link-exact-active {
      position: relative;
      background-color: #3a4891;

      &::after {
        content: "";
        width: 6px;
        height: 100%;
        background-color: #e4261b;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .bars {
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: 20px;
    width: 24px;
    height: 20px;
    color: #fff;
  }
}

.block {
  width: 95%;
  margin: auto;
  padding: 4px;
  overflow: auto;
  color: #fff;

  p {
    font-size: 14px;
    margin-bottom: 0px;
  }
}
</style>
