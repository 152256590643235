import axios from "axios";
import domain from "@/environment";

const state = {
  organisms: [], 
  organism: null, 
  organismsLoading: false, 
  organismRows: 0, 
};

const getters = {
  getAllOrganisms: (state) => state.organisms,
  getSingleOrganism: (state) => state.organism,
  isOrganismsLoading: (state) => state.organismsLoading,
  getTotalOrganismRows: (state) => state.organismRows,
};

const mutations = {
  SET_ORGANISMS(state, payload) {
    state.organisms = payload || [];
  },
  SET_SINGLE_ORGANISM(state, payload) {
    state.organism = payload || null;
  },
  ADD_ORGANISM(state, payload) {
    state.organisms.push(payload);
  },
  UPDATE_ORGANISM(state, payload) {
    const index = state.organisms.findIndex((item) => item.id === payload.id);
    if (index !== -1) {
      state.organisms.splice(index, 1, payload);
    }
  },
  DELETE_ORGANISM(state, payload) {
    state.organisms = state.organisms.filter((item) => item.id !== payload);
  },
  SET_ORGANISMS_LOADING(state, payload) {
    state.organismsLoading = payload || false;
  },
  SET_TOTAL_ROWS(state, payload) {
    state.organismRows = payload || 0;
  },
};

const actions = {

  async allOrganisms({ commit }, { page = 1, per_page = 10, filters = {} }) {
    commit("SET_ORGANISMS_LOADING", true);
    try {
      const response = await axios.get(`${domain}/organisms`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
        params: { page, per_page, ...filters },
      });
      commit("SET_ORGANISMS", response.data.data);
      commit("SET_TOTAL_ROWS", response.data.total || 0);
    } catch (error) {
      console.error("Error fetching organisms:", error.response?.data || error.message);
      throw error.response?.data.message || error.message;
    } finally {
      commit("SET_ORGANISMS_LOADING", false);
    }
  },

  async fetchOrganism({ commit }, organismId) {
    commit("SET_ORGANISMS_LOADING", true);
    try {
      const response = await axios.get(`${domain}/organisms/${organismId}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      commit("SET_SINGLE_ORGANISM", response.data.data);
    } catch (error) {
      console.error("Error fetching organism:", error.response?.data || error.message);
      throw error.response?.data.message || error.message;
    } finally {
      commit("SET_ORGANISMS_LOADING", false);
    }
  },

  async createOrganism({ commit }, payload) {
    try {
      const response = await axios.post(`${domain}/organisms`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      commit("ADD_ORGANISM", response.data.data);
      commit("SET_TOTAL_ROWS", state.organismRows + 1);
      return response.data.data;
    } catch (error) {
      console.error("Error creating organism:", error.response?.data || error.message);
      throw error.response?.data.message || error.message;
    }
  },

  async updateOrganism({ commit }, payload) {
    try {
      const response = await axios.put(`${domain}/organisms/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      commit("UPDATE_ORGANISM", response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Error updating organism:", error.response?.data || error.message);
      throw error.response?.data.message || error.message;
    }
  },

  async deleteOrganism({ commit }, organismId) {
    commit("SET_ORGANISMS_LOADING", true);
    try {
      await axios.delete(`${domain}/organisms/${organismId}`, {
        headers: {
          Authorization: `Bearer ${this.state.token}`,
        },
      });
      commit("DELETE_ORGANISM", organismId);
      commit("SET_TOTAL_ROWS", state.organismRows - 1);
    } catch (error) {
      console.error("Error deleting organism:", error.response?.data || error.message);
      throw error.response?.data.message || error.message;
    } finally {
      commit("SET_ORGANISMS_LOADING", false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
