var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sideClient"},[_c('nav',[(_vm.getTabs)?_c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$t("GENERAL"))+" "),_c('svg',{staticClass:"bars",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"27","height":"18","viewBox":"0 0 27 18"},on:{"click":_vm.changeTabs}},[_c('path',{attrs:{"id":"Icon_material-menu","data-name":"Icon material-menu","d":"M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z","transform":"translate(-4.5 -9)","fill":"#fff"}})])]),_c('ul',{staticClass:"subtitle"},[(
              _vm.isentrepriseAdmin ||
              _vm.isSuperAdmin ||
              _vm.isAssistant ||
              _vm.isTechnicien ||
              _vm.isCommercial
            )?_c('li',[_c('router-link',{attrs:{"to":"/client"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"user"}}),_vm._v(" "+_vm._s(_vm.$t("CUSTOMER"))+" ")],1)],1):_vm._e(),(
              _vm.isentrepriseAdmin ||
              _vm.isSuperAdmin ||
              _vm.isAssistant ||
              _vm.isCommercial ||
              _vm.isTechnicien ||
              _vm.isUserCLient
            )?_c('li',[_c('router-link',{attrs:{"to":"/devis/list"}},[_c('svg',{staticClass:"svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"27","height":"15","viewBox":"0 0 27 15"}},[_c('path',{attrs:{"id":"Icon_material-list","data-name":"Icon material-list","d":"M4.5,19.5h3v-3h-3Zm0,6h3v-3h-3Zm0-12h3v-3h-3Zm6,6h21v-3h-21Zm0,6h21v-3h-21Zm0-15v3h21v-3Z","transform":"translate(-4.5 -10.5)","fill":"#fff"}})]),_vm._v(" "+_vm._s(_vm.$t("ESTIMATE"))+" ")])],1):_vm._e(),(
              _vm.isentrepriseAdmin ||
              _vm.isSuperAdmin ||
              _vm.isComptable ||
              _vm.isAssistant ||
              _vm.isCommercial ||
              _vm.isUserCLient
            )?_c('li',[_c('router-link',{attrs:{"to":"/facture/list"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"receipt"}}),_vm._v(" "+_vm._s(_vm.$t("INVOICE"))+" ")],1)],1):_vm._e(),(
              _vm.isentrepriseAdmin || _vm.isSuperAdmin || _vm.isAssistant || _vm.isCommercial
            )?_c('li',[_c('router-link',{attrs:{"to":"/facture"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"file-invoice-dollar"}}),_vm._v(" "+_vm._s(_vm.$t("CREATE"))+" "+_vm._s(_vm.$t("INVOICE"))+" ")],1)],1):_vm._e(),(!_vm.isComptable)?_c('li',[_c('router-link',{attrs:{"to":"/agenda"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"fa-solid fa-calendar"}}),_vm._v(" "+_vm._s(_vm.$t("AGENDA"))+" ")],1)],1):_vm._e()])]),(_vm.isUserCLient)?_c('div',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("Contact artisant")))]),_c('div',{staticClass:"block"},[_c('p',[_vm._v("Nom: "+_vm._s(_vm.getOnlineUser.entreprise.lib))]),_c('p',[_vm._v("Adresse:")]),_c('p',[_vm._v(" "+_vm._s(_vm.getOnlineUser.entreprise.rue)+", "+_vm._s(_vm.getOnlineUser.entreprise.cp)+", "+_vm._s(_vm.getOnlineUser.entreprise.ville)+" ")]),_c('p',[_vm._v("Numéro: "+_vm._s(_vm.getOnlineUser.entreprise.tel))]),_c('p',[_vm._v("Email: "+_vm._s(_vm.getOnlineUser.entreprise.email))])])]):_vm._e()]):_vm._e(),(!_vm.getTabs)?_c('div',{staticClass:"sidebar minim"},[_c('div',{staticClass:"title"},[_c('h1',[_c('svg',{staticClass:"bars",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"27","height":"18","viewBox":"0 0 27 18"},on:{"click":_vm.changeTabs}},[_c('path',{attrs:{"id":"Icon_material-menu","data-name":"Icon material-menu","d":"M4.5,27h27V24H4.5Zm0-7.5h27v-3H4.5ZM4.5,9v3h27V9Z","transform":"translate(-4.5 -9)","fill":"#fff"}})])]),_c('ul',{staticClass:"subtitle"},[(
              _vm.isentrepriseAdmin ||
              _vm.isSuperAdmin ||
              _vm.isAssistant ||
              _vm.isTechnicien ||
              _vm.isCommercial
            )?_c('li',{attrs:{"title":_vm.$t('CUSTOMER')}},[_c('router-link',{attrs:{"to":"/client"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"user"}})],1)],1):_vm._e(),(
              _vm.isentrepriseAdmin ||
              _vm.isSuperAdmin ||
              _vm.isAssistant ||
              _vm.isCommercial ||
              _vm.isTechnicien ||
              _vm.isUserCLient
            )?_c('li',{attrs:{"title":_vm.$t('ESTIMATE')}},[_c('router-link',{attrs:{"to":"/devis/list"}},[_c('svg',{staticClass:"svg",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"27","height":"15","viewBox":"0 0 27 15"}},[_c('path',{attrs:{"id":"Icon_material-list","data-name":"Icon material-list","d":"M4.5,19.5h3v-3h-3Zm0,6h3v-3h-3Zm0-12h3v-3h-3Zm6,6h21v-3h-21Zm0,6h21v-3h-21Zm0-15v3h21v-3Z","transform":"translate(-4.5 -10.5)","fill":"#fff"}})])])],1):_vm._e(),(
              _vm.isentrepriseAdmin ||
              _vm.isSuperAdmin ||
              _vm.isComptable ||
              _vm.isAssistant ||
              _vm.isCommercial ||
              _vm.isUserCLient
            )?_c('li',{attrs:{"title":_vm.$t('INVOICE')}},[_c('router-link',{attrs:{"to":"/facture/list"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"receipt"}})],1)],1):_vm._e(),(
              _vm.isentrepriseAdmin || _vm.isSuperAdmin || _vm.isAssistant || _vm.isCommercial
            )?_c('li',{attrs:{"title":_vm.$t('INVOICE')}},[_c('router-link',{attrs:{"to":"/facture"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"file-invoice-dollar"}})],1)],1):_vm._e(),(!_vm.isComptable)?_c('li',{attrs:{"title":_vm.$t('AGENDA')}},[_c('router-link',{attrs:{"to":"/agenda"}},[_c('font-awesome-icon',{staticClass:"svg",attrs:{"icon":"fa-solid fa-calendar"}})],1)],1):_vm._e()])])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }