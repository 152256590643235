import domain from "@/environment";
import axios from "axios";
const state = {
  entiteExterne: null,
  entitesExternes: [],
  entiteExterneRows: 0,
  entiteExterneLoding:false,
};

const getters = {
  getLoadingEntiteExterne: (state) => state.entiteExterneLoding,
  getEntiteExterne: (state) => state.entiteExterne,
  getAllEntitesExternes: (state) => state.entitesExternes,
  getTotalRowEntiteExterne: (state) => state.entiteExterneRows,
  getPartenaireEntitesExternes: (state) => {
   
    return state.entitesExternes.filter(
      (entite) => entite.type === 'oblige'
    );
  },

  getMondataireEntitesExternes: (state) => {
    return state.entitesExternes.filter(
      (entite) => 
        entite.type === 'mandataire' || 
        entite.type === 'delegataire'
    );
  }
  
};

const mutations = {
  DO_NOTHING() {},
  SET_ENTITE_EXTERNE_LODING(state, payload) {
    state.entiteExterneLoding = payload || false;
  },
  SET_ENTITE_EXTERNE(state, payload) {
    state.entiteExterne = payload ? payload : null;
  },
  PUSH_ENTITE_EXTERNE(state, payload) {
    state.entitesExternes.push(payload);
  },
 
  UPDATE_ENTITE_EXTERNE(state, payload) {

    for (let index = 0; index < state.entitesExternes.length; index++) {
      if (state.entitesExternes[index].id === payload.id) {
        state.entitesExternes.splice(index, 1, payload);
        break;
      }
    }
  },
  DELETE_ENTITE_EXTERNE(state, payload) {
    state.entitesExternes = state.entitesExternes.filter(
      (item) => item.id !== payload.id
    );
  },

  UPDATE_ENTITE_EXTERNE_ROW(state, payload) {

    state.entiteExterneRows = payload ? payload : 0;
  },
  SET_ENTITES_EXTERNES(state, payload) {
    state.entitesExternes = payload ? payload : [];
  },
  
};

const actions = {
  async allEntitesExternes({ commit }, payload) {
    commit("SET_ENTITE_EXTERNE_LODING",true);
   
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      entreprise_id:payload.entreprise_id,
      nom: payload.nom,
      type: payload.type,
    };
    try {
      const response = await axios.get(domain + "/entites-externes", {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
        params,
      });
    
      if (response.data.data.data) {
        commit("UPDATE_ENTITE_EXTERNE_ROW", response.data.data.total);
        commit("SET_ENTITES_EXTERNES", response.data.data.data); 
      } else {
     
        commit("SET_ENTITES_EXTERNES", []);
      }
      commit("SET_ENTITE_EXTERNE_LODING",false);
      return true;
    } catch (error) {
     
      commit("SET_ENTITE_EXTERNE_LODING",false);
      throw error.response ? error.response.data.message : error.message;
    }
  },
  async entiteExterne({ commit }, payload) {
    commit("DO_NOTHING");
    try {
      const response = await axios.get(
        domain + `/entites-externes/` + payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );
      commit("SET_ENTITE_EXTERNE", response.data.data);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  }
,
async editEntiteExterne({ commit }, payload) {
  try {
      const response = await axios.put(
          domain + `/entites-externes/` + payload.id,
          payload,
          {
              headers: {
                  Authorization: `Bearer ` + this.state.token,
              },
          }
      );


          commit("UPDATE_ENTITE_EXTERNE", payload);
          return response.data.data;
      
  } catch (error) {
     
      return error.response && error.response.data ? error.response.data.message : error.message;
  }
},
  async storeEntiteExterne({ commit }, payload) {
    try {
      const response = await axios.post(
        domain + `/entites-externes`,
        payload,
        {
          headers: {
            Authorization: `Bearer ` + this.state.token,
          },
        }
      );

      commit("PUSH_ENTITE_EXTERNE", response.data.data);
      commit("UPDATE_ENTITE_EXTERNE_ROW", state.entiteExterneRows + 1);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
  async deleteEntiteExterne({ commit }, payload) {
    
    commit("SET_ENTITE_EXTERNE_LODING",true);
    try {
      await axios.delete(domain + `/entites-externes/` + payload.id, {
        headers: {
          Authorization: `Bearer ` + this.state.token,
        },
      });
      commit("DELETE_ENTITE_EXTERNE", payload);
      commit("UPDATE_ENTITE_EXTERNE_ROW", state.entiteExterneRows - 1);
      
      commit("SET_ENTITE_EXTERNE_LODING",false);
      return true;
    } catch (error) {
      throw error.response.data.message;
    }
  },
};

export default { state, mutations, getters, actions };
